import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}));

const FieldSelectList = ({
  options = [],
  value: propValue,
  fieldKey,
  onChange,
  error,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(propValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (typeof onChange === "function") onChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel htmlFor="list-select">{fieldKey || "Items"}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          id: "list-select",
        }}
      >
        {options.map((option, index) => {
          let item;
          if (option.value && typeof option !== "string") {
            item = (
              <MenuItem key={`item-[${index}]`} value={option.value}>
                {option.label}
              </MenuItem>
            );
          } else {
            item = (
              <MenuItem key={`item-[${index}]`} value={option}>
                {option}
              </MenuItem>
            );
          }
          return item;
        })}
      </Select>
    </FormControl>
  );
};

export default FieldSelectList;
