import objectKeyBy from "lodash-es/keyBy";
import {
  fields as defaultFieldTypes,
  defaults as defaultBackends,
} from "data/configurator/backend";

const defaultBackendsObject = objectKeyBy(defaultBackends, "key");
const defaultTypesObject = objectKeyBy(defaultFieldTypes, "key");

export const getViewFieldsByName = (name) => {
  const viewFields = [];
  if (name && defaultBackendsObject[name]) {
    defaultBackendsObject[name].fields
      .filter((k) => k !== "name")
      .forEach((fieldKey) => viewFields.push(defaultTypesObject[fieldKey]));
  }
  return viewFields;
};
