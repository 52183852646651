import { useState /* , useEffect */ } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import IncludeIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5,
    outline: `1px solid ${theme.palette.primary.light}`,
    padding: 5,
  },
  rootError: {
    outline: "1px solid red",
    padding: 5,
  },
  text: {
    paddingLeft: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipSelected: {
    color: theme.palette.primary.main,
  },
  chipNotSelected: {
    color: theme.palette.grey,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  rootValues: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
}));

const buildOptions = (options, selection, canEdit) => {
  if (canEdit) options = selection;
  return options
    ? options.map((obj) =>
        typeof obj === "string" ? { value: obj, label: obj } : obj
      )
    : [];
};

const FieldArrayOfString = ({
  title,
  options: propOptions,
  selection: propSelection,
  required,
  canEdit,
  onChange,
  error,
}) => {
  const classes = useStyles();
  const [selection, setSelection] = useState(
    propSelection /* selected items */ || propOptions /* all */
  );

  const [options, setOptions] = useState(
    buildOptions(propOptions, propSelection, canEdit)
  );
  const [valueOption, setValueOption] = useState("");
  const [valueError, setValueError] = useState(false);

  // state = {
  //   options: buildOptions(
  //     this.props.options,
  //     this.props.selection,
  //     this.props.canEdit
  //   ),
  //   selection: this.props.selection || this.props.options,
  //   canEdit: this.props.canEdit,
  //   required: this.props.required,
  //   valueOption: "",
  //   error: this.props.error,
  // };

  // const handleOnChange = () => {
  //   const { options, selection, canEdit } = this.state;
  //   const returnArray = canEdit ? options.map((obj) => obj.value) : selection;
  //   if (typeof onChange === "function") onChange(returnArray);
  // };

  // useEffect(() => {
  //   setOptions(buildOptions(propOptions, propSelection, canEdit));
  // }, [propOptions, propSelection, canEdit]);

  // useEffect(() => {
  //   const returnArray = canEdit ? options.map((obj) => obj.value) : selection;
  //   if (typeof onChange === "function") onChange(returnArray);
  // }, [options, selection, canEdit, onChange]);

  const handleAdd = () => {
    const newOptions = Array.from(options, (x) => x);
    if (!valueOption === "") {
      const chipValue = {
        value: valueOption,
        label: valueOption,
      };
      let chipIndex = newOptions.indexOf(valueOption);
      newOptions.forEach((element, index) => {
        if (element.value === chipValue.value) {
          chipIndex = index;
        }
      });
      if (chipIndex >= 0) {
        newOptions[chipIndex].label = chipValue.label;
      } else {
        newOptions.push(chipValue);
      }
      setOptions(newOptions);
      setValueOption("");
      // this.setState({ options, valueOption: "" }, this.handleOnChange);
    } else {
      setValueError(true);
    }
  };

  const handleChipClick = (data) => (event) => {
    setValueOption(data.value);
  };

  const handleTextChange = (tag) => (event) => {
    if (tag === "value") {
      setValueOption(event.target.value);
      setValueError(event.target.value === "");
    }
  };

  const handleOptionsDelete = (data) => () => {
    const newOptions = [...options];
    const chipToDelete = options.indexOf(data);
    newOptions.splice(chipToDelete, 1);
    setOptions(newOptions);
    // this.setState({ options }, this.handleOnChange);
  };

  const handleOptionsSelect = (data) => () => {
    const newSelection = [...selection];
    const chipToDelete = newSelection.indexOf(data.value);
    if (chipToDelete === -1) {
      newSelection.push(data.value);
    } else {
      newSelection.splice(chipToDelete, 1);
    }
    setSelection(newSelection);
    // this.setState({ selection }, this.handleOnChange);
  };

  return (
    <div className={error ? classes.rootError : classes.root}>
      <Typography use="headline">{title}</Typography>
      <Paper className={classes.rootValues}>
        {options.map((data, index) => {
          const viewChip = canEdit ? (
            <Chip
              key={`[${index}]`}
              label={data.label}
              onClick={handleChipClick(data)}
              onDelete={handleOptionsDelete(data)}
              className={classes.chip}
            />
          ) : (
            <Chip
              key={`[${index}]`}
              label={data.label}
              onClick={handleOptionsSelect(data)}
              onDelete={handleOptionsSelect(data)}
              deleteIcon={
                <IncludeIcon
                  className={
                    selection.indexOf(data.value) >= 0
                      ? classes.chipSelected
                      : classes.chipNotSelected
                  }
                />
              }
              className={classes.chip}
            />
          );
          return viewChip;
        })}
      </Paper>
      {canEdit && (
        <TextField
          className={classes.text}
          required={required}
          autoFocus
          error={valueError}
          margin="dense"
          id="value"
          label="value"
          onChange={handleTextChange("value")}
          value={valueOption}
          fullWidth
        />
      )}
      {canEdit && (
        <Button onClick={handleAdd} color="primary" disabled={valueError}>
          Add Value
        </Button>
      )}
    </div>
  );
};

export default FieldArrayOfString;
