import { useState, forwardRef } from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddFileIcon from "@material-ui/icons/AddCircleOutline";
import Slide from "@material-ui/core/Slide";

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const FileDialog = ({ path, description, onOpen, onClose, onSave }) => {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState({ name: "", file: "" });
  const [error, setError] = useState({
    nameError: value.name === "",
    fileError: value.file === "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    if (typeof onOpen === "function") onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (typeof onClose === "function") onClose();
  };

  const handleSave = () => {
    const newValue = Object.assign({}, value);
    if (newValue.name.length > 0 && newValue.file.length > 0) {
      const [firstChar, ...remainingChars] = newValue.name;
      newValue.label = [firstChar.toUpperCase(), ...remainingChars].join("");
      newValue.fields = [];

      if (typeof onSave === "function") onSave(newValue);
      setValue(newValue);
      setOpen(false);
    } else {
      setError({
        nameError: value.name === "",
        fileError: value.file === "",
      });
    }
  };

  const handleTextChange = (key) => (event) => {
    const newValue = Object.assign({}, value);
    newValue[key] = event.target.value;
    setValue(newValue);
    setError({
      ...error,
      [`${key}Error`]: event.target.value === "",
    });
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="Edit" color="primary">
        <AddFileIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add File</DialogTitle>
        <DialogContent>
          <TextField
            required
            error={error["nameError"]}
            autoFocus
            margin="dense"
            id="name"
            label="name"
            onChange={handleTextChange("name")}
            fullWidth
          />
          <TextField
            required
            error={error["fileError"]}
            margin="dense"
            id="file"
            label="file"
            onChange={handleTextChange("file")}
            fullWidth
          />
          <DialogContentText>{description}</DialogContentText>
          <Typography>path: {path}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Add File
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileDialog;
