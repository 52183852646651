import { red } from "@material-ui/core/colors";
/* the following "unstable_createMuiStrictModeTheme" solves prior to v5 */
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
// import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3f9fb2",
      main: "#3C6A84",
      dark: "#2b394d",
    },
    secondary: {
      main: "#2b394d",
      dark: "#1b2a49",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F8FCFF",
      set: "rgb(189, 239, 222, 0.75)",
      notset: "rgb(189, 239, 222, 0.5)",
    },
  },
  configurator: {
    layout: {
      borderWidth: "3px",
    },
  },
});

export default theme;
