import { useLocation } from "wouter";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Page from "components/PageComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "25vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(20px + 2vmin)",
  },
  sub: {
    minHeight: "25vh",
    textAlign: "center",
    fontSize: "calc(10px + 2vmin)",
  },
}));

const NotFound = (props) => {
  const [location, setLocation] = useLocation();

  const classes = useStyles();
  const data = {};
  return (
    <Page image={data.image} title={data.title} update={data.update}>
      <div className={classes.root}>
        <Typography className={classes.root} color="primary">
          Nobody knows the trouble I've seen.
          <Button
            color="primary"
            onClick={() => setLocation("/")}
            variant="outlined"
            disabled={location === "/"}
          >
            Return Home
          </Button>
        </Typography>
        <Typography className={classes.root} color="primary">
          Page Not found.
        </Typography>
      </div>
    </Page>
  );
};

export default NotFound;
