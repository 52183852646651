import { Helmet } from "react-helmet-async";
//
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DialogMenu from "components/DialogMenu";
import AppHeader from "components/AppHeader";

const useStyles = makeStyles((theme) => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  parallax: {
    /* Set a specific height */
    minHeight: 400,
    /* Create the parallax scrolling effect */
    backgroundAttachment: "fixed",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
  },
  fixed: {
    position: "fixed",
    bottom: 20,
    left: 20,
  },
}));

const PageComponent = (props) => {
  const { image, title = "Configurator", hideImage = "true" } = props;
  const classes = useStyles();

  return (
    <AppHeader>
      <DialogMenu />
      <Helmet titleTemplate="%s | NetlifyCMS Configurator">
        <title>{title}</title>
      </Helmet>
      <Typography variant="h1" align="center" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={6}>
        {!hideImage && <Grid item xs={12} sm={1} md={3} lg={3} />}
        {!hideImage && (
          <Grid item xs={12} sm={10} md={6} lg={6}>
            <img src={image} alt={title} />
          </Grid>
        )}
        {!hideImage && <Grid item xs={12} sm={1} md={3} lg={3} />}
        <Grid item xs={12} sm={1} md={4} lg={4} />
        <Grid className={classes.paper} item xs={12} sm={10} md={4} lg={4}>
          {props.children}
        </Grid>
        <Grid item xs={12} sm={1} md={4} lg={4} />
      </Grid>
    </AppHeader>
  );
};

export default PageComponent;
