import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CoreSelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5,
    outline: `1px solid ${theme.palette.primary.light}`,
    padding: 5,
  },
  rootError: {
    outline: "1px solid red",
    padding: 5,
  },
  text: {
    paddingLeft: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  rootValues: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
}));

const buildOptions = (options) => {
  return options
    ? options.map((obj) =>
        typeof obj === "string" ? { value: obj, label: obj } : obj
      )
    : [{ value: "none", label: "None" }];
};

const FieldSelect = ({
  title,
  options: propOptions,
  valueOnly: propValueOnly,
  onChange,
  required,
  error,
}) => {
  const classes = useStyles();

  const [options, setOptions] = useState(buildOptions(propOptions));
  const [valueOption, setValueOption] = useState("");
  const [valueError, setValueError] = useState(false);

  const [labelOption, setLabelOption] = useState("");
  const [labelError, setLabelError] = useState(false);

  const [valueOnly, setValueOnly] = useState(!!propValueOnly);

  // state = {
  //   options: buildOptions(this.props.options),
  //   required: this.props.required,
  //   valueOption: "",
  //   labelOption: "",
  //   valueOnly: !!this.props.valueOnly,
  //   error: this.props.error,
  // };

  const handleOnChange = () => {
    const returnArray = options.map((obj) => {
      if (valueOnly) return obj.value;
      return obj;
    });
    if (typeof onChange === "function") onChange(returnArray);
  };

  const handleAdd = () => {
    const newOptions = Array.from(options, (x) => x);
    const valueError = valueOption === "";
    const labelError = labelOption === "" && !valueOnly;

    if (!valueError && !labelError) {
      const chipValue = valueOnly
        ? { value: valueOption, label: valueOption }
        : { value: valueOption, label: labelOption };
      let chipIndex = -1;
      newOptions.forEach((element, index) => {
        if (element.value === chipValue.value) {
          chipIndex = index;
        }
      });
      if (chipIndex >= 0) {
        newOptions[chipIndex].label = chipValue.label;
      } else {
        newOptions.push(chipValue);
      }
      setValueOption("");
      setLabelOption("");
      setOptions(newOptions);
      handleOnChange();
      // this.setState(
      //   { options, valueOption: "", labelOption: "" },
      //   this.handleOnChange
      // );
    } else {
      setValueError(valueError);
      setLabelError(labelError);
      // this.setState({ valueError, labelError });
    }
  };

  const handleOptionsTypeChange = (event) => {
    const valueOnly = event.target.checked;
    const newOptions = valueOnly
      ? options.map((o) => ({ value: o.value, label: o.value }))
      : options;
    setOptions(newOptions);
    setValueOnly(valueOnly);
    handleOnChange();
    // this.setState({ options, valueOnly }, this.handleOnChange);
  };

  const handleChipClick = (data) => (event) => {
    setValueOption(data.value);
    setLabelOption(data.label);
    // this.setState({ valueOption: data.value, labelOption: data.label });
  };

  const handleTextChange = (tag) => (event) => {
    if (tag === "value") {
      setValueOption(event.target.value);
      setValueError(event.target.value === "");
    }
    if (tag === "label") {
      setLabelOption(event.target.value);
      setLabelError(event.target.value === "");
    }
    //  this.setState({
    //     [`${tag}Option`]: event.target.value,
    //     [`${tag}Error`]: event.target.value === "",
    //   });
  };

  const handleOptionsDelete = (data) => () => {
    const newOptions = [...options];
    const chipToDelete = newOptions.indexOf(data);
    newOptions.splice(chipToDelete, 1);
    setOptions(newOptions);
    handleOnChange();
    // this.setState({ options }, this.handleOnChange);
  };

  return (
    <div className={error ? classes.rootError : classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="example-select">{title || "Items"}</InputLabel>
        <CoreSelect
          key={Math.random()}
          value={valueOption}
          inputProps={{
            id: "example-select",
          }}
        >
          {options.map((option, index) => {
            let item;
            if (option.value && typeof option !== "string") {
              item = (
                <MenuItem key={`item-[${index}]`} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            } else {
              item = (
                <MenuItem key={`item-[${index}]`} value={option}>
                  {option}
                </MenuItem>
              );
            }
            return item;
          })}
        </CoreSelect>
      </FormControl>
      <Paper className={classes.rootValues}>
        {options.map((data, index) => {
          return (
            <Chip
              key={`[${index}]`}
              label={data.label}
              onClick={handleChipClick(data)}
              onDelete={handleOptionsDelete(data)}
              className={classes.chip}
            />
          );
        })}
      </Paper>
      <TextField
        className={classes.text}
        required={required}
        autoFocus
        error={valueError}
        margin="dense"
        id="value"
        label="value"
        onChange={handleTextChange("value")}
        value={valueOption}
        fullWidth
      />
      {!valueOnly && (
        <TextField
          className={classes.text}
          required={required}
          autoFocus
          error={labelError}
          margin="dense"
          id="label"
          label="label"
          onChange={handleTextChange("label")}
          value={labelOption}
          fullWidth
        />
      )}
      <Button
        onClick={handleAdd}
        color="primary"
        disabled={labelError || valueError}
      >
        Add Option
      </Button>
      <FormControlLabel
        control={
          <Switch
            key="valueOnly"
            checked={valueOnly === undefined ? false : valueOnly}
            onClick={handleOptionsTypeChange}
            value="value"
          />
        }
        label="Value Only"
      />
    </div>
  );
};

export default FieldSelect;
