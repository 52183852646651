import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import { defaults as defaultWidgets } from "data/configurator/widget";

const useStyles = makeStyles((theme) => ({
  text: {
    paddingLeft: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}));

const WidgetSelect = ({ value, required, onChange, onError }) => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(value);
  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState(value === "");
  const widgetList = defaultWidgets.map((widget) => widget.key);

  useEffect(() => {
    if (typeof onError === "function") onError(error);
  }, [error, onError]);

  const handleOnChange = (newValue) => {
    if (newValue !== value) {
      setSelectValue(newValue);
      setTextValue(newValue);
      if (typeof onChange === "function") onChange(newValue);
    }

    setError(!widgetList.includes(newValue));
  };

  const handleSelectChange = (event) => {
    handleOnChange(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
    if (widgetList.includes(event.target.value)) {
      handleOnChange(event.target.value);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="widget-select">Default Widgets</InputLabel>
        <Select
          value={selectValue}
          onChange={handleSelectChange}
          inputProps={{
            id: "widget-select",
          }}
          error={error}
        >
          {!false && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {defaultWidgets.map((widget) => (
            <MenuItem key={widget.key} value={widget.key}>
              {widget.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={classes.text}
        required={required}
        autoFocus
        error={error}
        margin="dense"
        id="widget"
        label="widget"
        value={textValue}
        onChange={handleTextChange}
        fullWidth
      />
    </>
  );
};

export default WidgetSelect;
