import { useState, useEffect, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import isEqual from "lodash-es/isEqual";

import LogoIcon from "components/configurator/LogoIcon";
import { getViewFieldsByName } from "components/configurator/Backend/view-fields";

import BackendSelect from "./Select";

const useStyles = makeStyles((theme) => ({
  rootForm: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const BackendEditDialog = ({
  backend,
  path,
  description,
  onOpen,
  onCancel,
  onSave,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editedBackend, setBackend] = useState(backend || {});
  const [keyIsErrors, setKeyIsErrors] = useState({});
  const [name, setName] = useState(
    (editedBackend && editedBackend.name) || "github"
  );
  const [fields, setFields] = useState(getViewFieldsByName(name));
  const [isChanged, setIsChanged] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (typeof onOpen === "function") onOpen();
  };

  const handleCancel = () => {
    setBackend(backend || {});
    setIsChanged(false);
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
  };

  const handleSave = () => {
    const { name = "" } = editedBackend;
    if (name.length > 0) {
      const returnValue = Object.assign({}, editedBackend);
      if (typeof onSave === "function") onSave(returnValue);
      setOpen(false);
    } else {
      setKeyIsErrors([]);
    }
  };

  const handleTextChange = (key) => (event) => {
    const newBackend = Object.assign({}, editedBackend);
    newBackend[key] = event.target.value;
    setBackend(newBackend);
    setIsChanged(!isEqual(newBackend, backend));
    keyIsErrors[key] = event.target.value === "";
    setKeyIsErrors(keyIsErrors);
  };

  const handleBooleanChange = (key) => (event) => {
    const newBackend = { ...editedBackend };
    newBackend[key] = event.target.checked;
    setBackend(newBackend);
    setIsChanged(!isEqual(newBackend, backend));
  };

  useEffect(() => {
    // make sure the name was changed (initial check also)
    if (!name || name === editedBackend.name) return;
    const newBackend = { name };
    const fields = getViewFieldsByName(name);
    // Assign the old state values to the new backend fields
    fields.forEach((field) => {
      newBackend[field.key] = editedBackend[field.key];
    });
    setFields(fields);
    setBackend(newBackend);
    setIsChanged(!isEqual(newBackend, backend));
  }, [name, editedBackend, backend]);

  const handleNameSelectChange = (name) => {
    setName(name);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="Edit" color="primary">
        <LogoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Backend</DialogTitle>
        <DialogContent>
          <form className={classes.rootForm} autoComplete="off">
            <FormControl className={classes.formControl}>
              <BackendSelect
                required
                value={editedBackend.name}
                onChange={handleNameSelectChange}
              />
              {fields.map((field, index) => {
                const fieldKey = field.key;
                let returnControl;
                switch (field.type) {
                  case "list":
                    break;
                  case "boolean":
                    returnControl = (
                      <FormControlLabel
                        key={`[${index}]`}
                        control={
                          <Switch
                            checked={
                              editedBackend[`${fieldKey}`] === undefined
                                ? false
                                : editedBackend[`${fieldKey}`]
                            }
                            onClick={handleBooleanChange(`${fieldKey}`)}
                            value={fieldKey}
                          />
                        }
                        label={fieldKey}
                      />
                    );
                    break;
                  default:
                    // 'string'
                    returnControl = (
                      <TextField
                        key={`[${index}]`}
                        required={field.required}
                        error={keyIsErrors[fieldKey]}
                        margin="dense"
                        id={fieldKey}
                        label={fieldKey}
                        defaultValue={editedBackend[`${fieldKey}`]}
                        onChange={handleTextChange(`${fieldKey}`)}
                        fullWidth
                      />
                    );
                }
                return returnControl;
              })}
            </FormControl>
          </form>
          <DialogContentText>{description}</DialogContentText>
          <Typography>path: {path}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!isChanged}>
            Change Backend
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BackendEditDialog;
