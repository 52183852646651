import { useState, useEffect, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import AddFieldIcon from "@material-ui/icons/Add";
import LogoIcon from "components/configurator/LogoIcon";
import omitObject from "lodash-es/omit";
import isEqual from "lodash-es/isEqual";

import WidgetSelect from "components/configurator/EditDialog/WidgetSelect";
import {
  getWidgetViewFieldsByName,
  getWidgetObjectByName,
  getWidgetFieldByName,
} from "components/configurator/Field/view-fields";
import FieldSelect from "components/configurator/EditDialog/Field/component/Select";
import FieldSelectList from "components/configurator/EditDialog/Field/component/SelectList";
import FieldHaveFields from "components/configurator/EditDialog/Field/component/HaveFields";
import ArrayOfString from "components/configurator/EditDialog/Field/component/ArrayOfString";

const useStyles = makeStyles((theme) => ({
  rootForm: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  error: {
    color: "red",
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const FieldUpdateDialog = ({
  config,
  path,
  mode,
  description,
  onOpen,
  onCancel,
  onSave,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [field, setField] = useState(config);
  const [widget, setWidget] = useState(field.widget || "string");
  const [viewFields, setViewFields] = useState(
    getWidgetViewFieldsByName(field.widget || "string")
  );
  const [errors, setErrors] = useState({});
  const [widgetDescription, setWidgetDescription] = useState(
    getWidgetObjectByName(widget).description || ""
  );
  const [isSelectError, setIsSelectError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (widget === field.widget) return;
    const widgetObject = getWidgetObjectByName(widget);
    const widgetDescription = widgetObject
      ? widgetObject.description
      : `Missing [${widget}]`;
    setWidgetDescription(widgetDescription);

    const newField = { widget };
    const newFields = getWidgetViewFieldsByName(widget || "string");
    newFields.forEach((defaultField) => {
      if (field[defaultField.key] !== undefined)
        newField[defaultField.key] = field[defaultField.key];
    });
    setField(newField);
    setViewFields(newFields);
    setIsChanged(!isEqual(newField, config));
  }, [widget, field, config]);

  const handleClickOpen = () => {
    setOpen(true);
    if (typeof onOpen === "function") onOpen();
  };

  const handleCancel = () => {
    setWidget(config.widget);
    setField(config);
    setIsChanged(false);
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
  };

  const handleSave = () => {
    const requiredErrors = viewFields.filter(
      (viewField) => viewField.required && !field[viewField.key]
    );
    if (requiredErrors && requiredErrors.length === 0) {
      const returnValue = Object.assign({}, field);
      if (returnValue.widget.toLowerCase() === "object") {
        returnValue.fields = returnValue.fields || [];
      }
      if (typeof onSave === "function") onSave(returnValue);
      setOpen(false);
    } else {
      const errors = {};
      requiredErrors.forEach((field) => {
        errors[`${field.key}Error`] = true;
      });
      setErrors(errors);
    }
  };

  const handleTextChange = (key) => (event) => {
    const newField = Object.assign({}, field);
    newField[key] = event.target.value;
    setField(newField);
    setIsChanged(!isEqual(newField, config));
    const newErrors = { ...errors, [`${key}Error`]: event.target.value === "" };
    setErrors(newErrors);
    // this.setState({ field, [`${key}Error`]: event.target.value === "" });
  };

  const handleBooleanChange = (key) => (event) => {
    const newField = Object.assign({}, field);
    newField[key] = event.target.checked;
    setField(newField);
    setIsChanged(!isEqual(newField, config));
  };

  const handleSelectListChange = (key) => (returnValue) => {
    const newField = Object.assign({}, field);
    newField[key] = returnValue;
    setField(newField);
    setIsChanged(!isEqual(newField, config));
  };

  const handleSelectChange = (widget) => {
    setWidget(widget);
  };

  const handleSelectError = (isError) => {
    setIsSelectError(isError);
  };

  const handleFieldChange = (key) => (data) => {
    const newField = Object.assign({}, field);
    newField[key] = data;
    setField(newField);
    setIsChanged(!isEqual(newField, config));
  };

  const handleHaveFieldsChange = (key) => (data) => {
    let newField = Object.assign({}, field);
    switch (data) {
      case "field":
        newField = omitObject(newField, "fields");
        newField.field = [
          { name: "added_by_config", label: "Added by Config", widget: "text" },
        ];
        break;
      case "fields":
        newField = omitObject(newField, "field");
        newField.fields = [];
        break;
      default:
        newField = omitObject(newField, ["fields", "field"]);
    }
    setField(newField);
    setIsChanged(!isEqual(newField, config));
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="Edit" color="primary">
        {mode === "edit" && <LogoIcon />}
        {!(mode === "edit") && <AddFieldIcon />}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`${
          mode === "edit" ? "Update" : "Add"
        } Field`}</DialogTitle>
        <DialogContent>
          <form className={classes.rootForm} autoComplete="off">
            <WidgetSelect
              required
              value={field.widget}
              onChange={handleSelectChange}
              onError={handleSelectError}
            />
            {viewFields.map((viewField) => {
              const fieldKey = viewField.key;
              const widgetField = getWidgetFieldByName(fieldKey) || {};
              let returnControl;
              switch (viewField.type) {
                case "list":
                  returnControl = (
                    <Typography
                      key={fieldKey}
                      className={classes.error}
                    >{`Missing Component Type ${viewField.type}`}</Typography>
                  );
                  break;
                case "select":
                  returnControl = (
                    <FieldSelect
                      key={fieldKey}
                      value={field[`${fieldKey}`] || []}
                      title={fieldKey}
                      onChange={handleFieldChange(`${fieldKey}`)}
                    />
                  );
                  break;
                case "selectlist":
                  returnControl = (
                    <FieldSelectList
                      key={fieldKey}
                      fieldKey={fieldKey}
                      value={field[`${fieldKey}`] || ""}
                      options={widgetField.options}
                      onChange={handleSelectListChange(`${fieldKey}`)}
                    />
                  );
                  break;
                case "havefields":
                  returnControl = (
                    <FieldHaveFields
                      key={fieldKey}
                      fieldKey={fieldKey}
                      value={
                        field.field ? "field" : field.fields ? "fields" : "none"
                      }
                      onChange={handleHaveFieldsChange(`${fieldKey}`)}
                    />
                  );
                  break;

                case "boolean":
                  returnControl = (
                    <FormGroup key={fieldKey}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              field[`${fieldKey}`] === undefined
                                ? false
                                : field[`${fieldKey}`]
                            }
                            onClick={handleBooleanChange(`${fieldKey}`)}
                            value={fieldKey}
                          />
                        }
                        label={fieldKey}
                      />
                    </FormGroup>
                  );
                  break;
                case "string": // 'string'
                  returnControl = (
                    <TextField
                      key={fieldKey}
                      required={viewField.required}
                      error={errors[`${fieldKey}Error`]}
                      margin="dense"
                      id={fieldKey}
                      label={fieldKey}
                      onChange={handleTextChange(`${fieldKey}`)}
                      defaultValue={field[`${fieldKey}`]}
                      fullWidth
                    />
                  );
                  break;
                case "number":
                  returnControl = (
                    <TextField
                      key={fieldKey}
                      required={viewField.required}
                      error={errors[`${fieldKey}Error`]}
                      margin="dense"
                      id={fieldKey}
                      label={fieldKey}
                      type="number"
                      onChange={handleTextChange(`${fieldKey}`)}
                      defaultValue={field[`${fieldKey}`]}
                      fullWidth
                    />
                  );
                  break;

                case "arrayofstring":
                  returnControl = (
                    <ArrayOfString
                      key={viewField.key}
                      selection={field[`${fieldKey}`]}
                      options={widgetField.options}
                      canEdit={widgetField.edit}
                      title={fieldKey}
                      onChange={handleFieldChange(`${fieldKey}`)}
                    />
                  );
                  break;
                default:
                  returnControl = (
                    <Typography
                      key={fieldKey}
                      className={classes.error}
                    >{`Missing Component Type ${viewField.type}`}</Typography>
                  );
                  break;
              }
              return returnControl;
            })}
          </form>
          <DialogContentText>{widgetDescription}</DialogContentText>
          <Typography>path: {path}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={!isChanged || isSelectError}
          >
            {`${mode === "edit" ? "Update" : "Add"} Field`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FieldUpdateDialog;
