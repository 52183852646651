import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import FieldIcon from "@material-ui/icons/Label";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import DisplayField from "components/configurator/Field/display";
import Fields from "components/configurator/Fields";
import FieldUpdateDialog from "components/configurator/EditDialog/Field/update";
import FieldDeleteDialog from "components/configurator/EditDialog/Field/delete";
import { getWidgetViewFieldsByName } from "components/configurator/Field/view-fields";
import { usePrevious } from "components/hooks";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";
import get from "lodash-es/get";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const Field = ({ index, config, path, parentPath, onRemoveClose }) => {
  const classes = useStyles();
  const { editingPath } = useConfiguratorState();

  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [isEditing, setIsEditing] = useState(editingPath === path);
  const [viewFields, setViewFields] = useState(
    getWidgetViewFieldsByName(config.widget)
  );
  const updatedPath = usePrevious(editingPath);
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === path);
  }, [editingPath, path]);

  useEffect(() => {
    if (!path || path !== updatedPath) return;
    /* Set new values of edit items, if just edited */
    const pathConfig = get(config, path, {});
    setViewFields(getWidgetViewFieldsByName(pathConfig.widget));
  }, [path, updatedPath, config]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleUpdateOpen = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleUpdateClose = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleUpdateField = (value) => {
    dispatch({
      type: "UPDATE_FIELDS",
      value,
      path,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleClickRemoveOpen = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleRemoveClose = () => {
    setOpenRemove(false);
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    if (typeof onRemoveClose === "function") onRemoveClose();
  };

  const handleDelete = (parentPath) => {
    dispatch({
      type: "DELETE_FIELD_COLLECTION_ITEM",
      path: parentPath,
      index,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <>
      <ListItem button className={classes.set} onClick={handleClick}>
        <ListItemIcon>
          <FieldIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`${config.label || config.name} - ${config.widget}${
            isEditing ? " - editing" : ""
          }`}
        />
        <ListItemSecondaryAction>
          {open && (
            <FieldUpdateDialog
              config={config}
              path={`${path}`}
              mode="edit"
              description="Update the Field by entering the required values."
              onOpen={handleUpdateOpen}
              onCancel={handleUpdateClose}
              onSave={handleUpdateField}
            />
          )}
          <FieldDeleteDialog
            title="Remove Field"
            field={{
              required: false,
              description: `A field collection item [${
                config.label || config.name
              }]`,
            }}
            path={path}
            parentPath={parentPath}
            isSet
            open={openRemove}
            onOpen={handleClickRemoveOpen}
            onCancel={handleRemoveClose}
            onDelete={handleDelete}
            description={`Are you sure you want to remove Field ${
              config.label || config.name
            } from the configuration?`}
          />
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding>
          {!isEditing &&
            viewFields.map((field, index) => {
              const fieldKey = field.key;
              if (field.noDisplay) return null;
              return (
                <DisplayField
                  key={`[${index}]`}
                  field={field}
                  value={config[fieldKey]}
                  defaultValue={config[field.default] || field.default}
                  path={`${path}.${fieldKey}`}
                  parentPath={`${path}`}
                />
              );
            })}
          {config.fields && (
            <Fields fields={config.fields} path={`${path}.fields`} />
          )}
        </List>
      </Collapse>
    </>
  );
};

export default Field;
