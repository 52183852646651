import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const FieldHaveFields = ({ value: propValue, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(propValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (typeof onChange === "function") onChange(value);
  };

  return (
    <FormControl component="fieldset" required className={classes.formControl}>
      <FormLabel component="legend">Field/Fields</FormLabel>
      <RadioGroup
        aria-label="field-type"
        name="fieldtype"
        className={classes.group}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="fields" control={<Radio />} label="Fields" />
        <FormControlLabel value="field" control={<Radio />} label="Field" />
        <FormControlLabel value="none" control={<Radio />} label="None" />
      </RadioGroup>
    </FormControl>
  );
};

export default FieldHaveFields;
