import { useState, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ViewIcon from "@material-ui/icons/Visibility";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ReactJson from "react-json-view";
import YAML from "yamljs";

import { useConfiguratorState } from "components/configurator/provider";

import { downloadFile } from "components/utils/download-file";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  yamlSwitchRoot: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  yamlSwitchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.grey[300],
      "& + $track": {
        backgroundColor: theme.palette.grey[500],
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.grey[500],
      border: "6px solid #fff",
    },
  },
  checked: {},
  focusVisible: {},
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  thumb: {
    width: 24,
    height: 24,
  },
  yamlLabel: {
    color: theme.palette.common.white,
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="down" {...props} />;
});

const ViewerDropDialog = ({
  path,
  isSet,
  onOpen,
  onCancel,
  onClip,
  view: propsView = "yaml",
}) => {
  const classes = useStyles();
  const { config = {} } = useConfiguratorState();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(config);
  const [view, setView] = useState(propsView);

  const handleClickOpen = () => {
    if (typeof onOpen === "function") onOpen();
    setOpen(true);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") onCancel();
    setOpen(false);
  };

  const handleFormatChange = (name) => (event) => {
    setView(event.target.checked ? "yaml" : "json");
  };
  const handleDownload = () => (event) => {
    const filename = view === "yaml" ? "config.yml" : "config.json";
    const data =
      view === "yaml" ? YAML.stringify(copied, 100, 2) : JSON.stringify(copied);
    const mime = view === "yaml" ? "text/yaml" : "application/json";
    downloadFile(data, filename, mime);
  };

  const handleClipboard = (copy) => {
    setCopied(copy.src);
    if (onClip && typeof onClip === "function") onClip(copy.src);
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        aria-label="Edit"
        color={isSet ? "primary" : "secondary"}
      >
        <ViewIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleCancel}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="inherit"
              className={classes.flex}
            >
              {`${view && view.toUpperCase()} View`}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    root: classes.yamlSwitchRoot,
                    switchBase: classes.yamlSwitchBase,
                    track: classes.track,
                    thumb: classes.thumb,
                    checked: classes.checked,
                  }}
                  focusVisibleClassName={classes.focusVisible}
                  disableRipple
                  checked={view === "yaml"}
                  onChange={handleFormatChange("formatType")}
                  value="formatType"
                />
              }
              color="inherit"
              label={
                <Typography className={classes.yamlLabel}>{`${
                  view && view.toUpperCase()
                }`}</Typography>
              }
            />
            <Button
              onClick={handleDownload()}
              color="inherit"
              disabled={!copied}
            >
              Download <DownloadIcon className={classes.rightIcon} />
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {open && view === "json" && (
            <ReactJson
              name={false}
              src={config}
              theme="monokai"
              displayObjectSize={false}
              collapseStringsAfterLength={25}
              enableClipboard={handleClipboard}
            />
          )}
          {open && copied && view === "yaml" && (
            <pre>{YAML.stringify(copied, 100, 2)}</pre>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewerDropDialog;
