import { useState, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import UploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DropZone from "react-dropzone";
import YAML from "yamljs";

import { useConfiguratorDispatch } from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  dropzone: {
    margin: theme.spacing(5),
    textAlign: "center",
    border: "3px dashed grey",
    minHeight: 300,
    borderRadius: 10,
  },
  bigIcon: {
    width: 60,
    height: 60,
    color: theme.palette.primary.dark,
  },
  fileError: {
    color: "red",
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="down" {...props} />;
});

const UploadDialog = ({ onOpen, onCancel }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fileError, setFileError] = useState("");
  const [files, setFiles] = useState([]);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const dispatch = useConfiguratorDispatch();

  const handleClickOpen = () => {
    if (typeof onOpen === "function") onOpen();
    setOpen(true);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") onCancel();
    setOpen(false);
    setFiles([]);
    setFileError("");
  };

  const onDragEnter = () => {
    setDropzoneActive(true);
  };

  const onDragLeave = () => {
    setDropzoneActive(false);
  };

  const onDrop = (files) => {
    setFileError("");
    if (files.length === 1) {
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsTextString = reader.result;
          // Send result to loaded file action after conversion
          const isJSON = file.type.indexOf("json") !== -1;
          try {
            const obj = isJSON
              ? JSON.parse(fileAsTextString)
              : YAML.parse(fileAsTextString);

            dispatch({
              type: "LOAD_CONFIG",
              config: obj,
              editingPath: "_root",
            });
          } catch (error) {
            setFileError(error.message);
          }
          dispatch({
            type: "EDITING_PATH",
            editingPath: "",
          });
        };
        reader.onabort = () => setFileError("file reading was aborted");
        reader.onerror = () => setFileError("file reading has failed");

        dispatch({
          type: "EDITING_PATH",
          editingPath: "_root",
        });
        reader.readAsText(file);
      });
    } else {
      setFileError("Please upload one file at a time!");
    }
    setFiles(files);
    setDropzoneActive(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="Edit" color="primary">
        <UploadIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleCancel}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="inherit"
              className={classes.flex}
            >
              Upload Config File
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {open && (
            <DropZone
              onDrop={onDrop}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classes.dropzone} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Typography variant="body1" color="primary">
                    {dropzoneActive
                      ? "Drop files..."
                      : "Drop file or Click here"}
                  </Typography>
                  {!dropzoneActive && (
                    <UploadIcon className={classes.bigIcon} />
                  )}

                  {files && files.length === 1 && (
                    <Typography variant="subtitle2" color="inherit">
                      Uploaded file
                    </Typography>
                  )}
                  {files &&
                    files.length === 1 &&
                    files.map((f) => (
                      <Typography key={f.name}>
                        {f.name} - {f.size} bytes
                      </Typography>
                    ))}
                  {fileError && (
                    <div className={classes.fileError}>{fileError}</div>
                  )}
                </div>
              )}
            </DropZone>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadDialog;
