import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import RemoveIcon from "@material-ui/icons/Remove";

import FieldEditDialog from "components/configurator/EditDialog/Field";
import FieldDeleteDialog from "components/configurator/EditDialog/Field/delete";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  notSet: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.background.notset,
  },
}));

const DisplayField = ({
  value: propValue,
  defaultValue,
  field,
  path,
  parentPath,
  onClose,
  onRemoveClose,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const [value, setValue] = useState(propValue);
  const [isSet, setIsSet] = useState(typeof propValue !== "undefined");
  const [secondary, setSecondary] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const { editingPath } = useConfiguratorState();
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === path);
  }, [editingPath, path]);

  useEffect(() => {
    const isSet = typeof value !== "undefined";
    setIsSet(isSet);
    const secondaryText = isSet
      ? typeof value === "string"
        ? value
        : JSON.stringify(value)
      : defaultValue || "not set";
    setSecondary(secondaryText);
  }, [value, defaultValue]);

  useEffect(() => {
    /* Important to track for new value applied */
    setValue(propValue);
  }, [propValue]);

  const handleEditOpen = () => {
    setOpen(true);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleEditClose = () => {
    setOpen(false);
    setValue(propValue);
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    if (typeof onClose === "function") onClose();
  };

  const handleClickRemoveOpen = () => {
    setOpenRemove(true);

    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleRemoveClose = () => {
    setOpenRemove(false);

    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    if (typeof onRemoveClose === "function") onRemoveClose();
  };

  const handleDelete = (updatePath) => {
    if (!field.required && updatePath === parentPath) {
      dispatch({
        type: "DELETE_FIELD",
        index: field.key,
        path: updatePath,
      });
    }
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    setOpenRemove(false);
  };

  const handleSave = (fieldValue) => {
    if (fieldValue !== value) {
      setValue(fieldValue);
      dispatch({
        type: "UPDATE_FIELD",
        path,
        value: fieldValue,
      });
    }
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <ListItem className={isSet ? classes.set : classes.notSet}>
      <ListItemIcon>
        <RemoveIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={`${field.key}${field.required ? " *" : ""}${
          isEditing ? " - editing" : ""
        }`}
        secondary={secondary ? `${secondary}` : null}
      />
      <ListItemSecondaryAction>
        {isSet && (
          <FieldDeleteDialog
            field={field}
            index={field.key}
            parentPath={parentPath}
            path={path}
            isSet={isSet}
            open={openRemove}
            onOpen={handleClickRemoveOpen}
            onCancel={handleRemoveClose}
            onDelete={handleDelete}
            description="Are you sure you want to clear this field from the configuration?"
          />
        )}
        <FieldEditDialog
          field={field}
          value={value}
          path={path}
          isSet={isSet}
          open={open}
          onOpen={handleEditOpen}
          onCancel={handleEditClose}
          onSave={handleSave}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DisplayField;
