import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import FieldsIcon from "@material-ui/icons/ViewList";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import Field from "components/configurator/Field";
import FieldUpdateDialog from "components/configurator/EditDialog/Field/update";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const Fields = ({ path, fields = [] }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [list, setList] = useState(fields);
  const { editingPath } = useConfiguratorState();
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === path);
  }, [editingPath, path]);

  useEffect(() => {
    const list = fields || [];
    setList(list);
  }, [fields]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddOpen = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleAddCancel = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleAddField = (value) => {
    dispatch({
      type: "ADD_FIELD",
      path,
      value,
    });

    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <>
      <ListItem button className={classes.set} onClick={handleClick}>
        <ListItemIcon>
          <FieldsIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`Fields${isEditing ? " - editing" : ""}`}
        />
        <ListItemSecondaryAction>
          {fields && open && (
            <FieldUpdateDialog
              config={{ widget: "string" }}
              path={`${path}`}
              mode="add"
              description="Add a field by entering the required values."
              onOpen={handleAddOpen}
              onCancel={handleAddCancel}
              onSave={handleAddField}
            />
          )}
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding dense>
          {!isEditing &&
            list.map((field, index) => (
              <Field
                config={field}
                key={`${path}[${index}]`}
                path={`${path}[${index}]`}
                parentPath={`${path}`}
                index={index}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default Fields;
