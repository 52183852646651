import { useState, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  warning: {
    color: "red",
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const FieldDeleteDialog = ({
  field,
  path,
  parentPath,
  title,
  description,
  onOpen,
  onCancel,
  onDelete,
  isSet,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (typeof onOpen === "function") onOpen();
  };

  const handleCancel = () => {
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
  };

  const handleDelete = () => {
    setOpen(false);
    if (typeof onDelete === "function") onDelete(parentPath);
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        aria-label="Delete"
        color={isSet ? "primary" : "secondary"}
        disabled={field.required}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {title || "Delete Field"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.warning}>
            {description}
          </DialogContentText>
          <Divider />
          <DialogContentText>{field.description}</DialogContentText>
          <Typography>path: {path}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            {title || "Delete Field"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FieldDeleteDialog;
