import { useLocation } from "wouter";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Page from "components/PageComponent";
import "./about.css";
import logo from "../logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  logo: {
    height: "30vmin",
    pointerEvents: "none",
    marginBottom: "10vmin",
  },
  spin: {
    animation: "App-logo-spin infinite 20s linear",
  },
}));

const About = (props) => {
  const [, setLocation] = useLocation();
  const classes = useStyles();
  const about = {};
  const spinit = useMediaQuery("(prefers-reduced-motion: no-preference)");
  return (
    <Page image={about.image} title={about.title} update={about.update}>
      <div>
        <Typography className={classes.root} color="primary">
          <img
            src={logo}
            className={`${classes.logo} ${spinit ? classes.spin : ""}`}
            alt="logo"
          />
          <span>
            This configurator targets the <code>NetlifyCMS config</code>.{" "}
          </span>
          <Button
            color="primary"
            onClick={() => setLocation("/")}
            variant="outlined"
          >
            Return Home
          </Button>
        </Typography>
      </div>
    </Page>
  );
};

export default About;
