import { useState, useEffect, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import Slide from "@material-ui/core/Slide";

import FieldSelect from "components/configurator/EditDialog/Field/component/Select";
import FieldSelectList from "components/configurator/EditDialog/Field/component/SelectList";
import ArrayOfString from "components/configurator/EditDialog/Field/component/ArrayOfString";
import { getWidgetFieldByName } from "components/configurator/Field/view-fields";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const FieldEditDialog = ({
  field,
  path,
  parentPath,
  title,
  onOpen,
  onCancel,
  onSave,
  onDelete,
  isSet,
  value,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  const [isChanged, setIsChanged] = useState(false);
  const [EditComponent, setEditComponent] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    if (typeof onOpen === "function") onOpen();
  };

  const handleCancel = () => {
    setEditingValue(value);
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
  };

  const handleSave = (editValue) => (event) => {
    if (field.type === "boolean") editValue = !!editValue;
    if (typeof onSave === "function") onSave(editValue);
    setOpen(false);
  };

  const handleBooleanChange = (event) => {
    setEditingValue(event.target.checked);
  };

  const handleTextChange = (event) => {
    setEditingValue(event.target.value);
  };

  const handleArrayListChange = (key) => (data) => {
    setEditingValue(data);
  };

  useEffect(() => {
    setIsChanged(value !== editingValue);
  }, [value, editingValue]);

  useEffect(() => {
    const widgetField = getWidgetFieldByName(field.key) || {};

    let EditComponent = null;
    switch (field.type) {
      case "string":
        EditComponent = (
          <TextField
            required={field.required}
            autoFocus
            margin="dense"
            id={field.key}
            label={field.key}
            onChange={handleTextChange}
            defaultValue={editingValue}
            fullWidth
          />
        );
        break;
      case "number":
        EditComponent = (
          <TextField
            required={field.required}
            autoFocus
            margin="dense"
            id={field.key}
            label={field.key}
            type="number"
            onChange={handleTextChange}
            defaultValue={editingValue}
            fullWidth
          />
        );
        break;
      case "boolean":
        EditComponent = (
          <FormControlLabel
            control={
              <Switch
                checked={editingValue === undefined ? false : editingValue}
                onClick={handleBooleanChange}
                value="checked"
              />
            }
            label={field.key}
          />
        );
        break;
      case "select":
        EditComponent = (
          <FieldSelect
            options={editingValue}
            title={field.key}
            onChange={handleArrayListChange(`${field.key}`)}
          />
        );
        break;
      case "selectlist":
        EditComponent = (
          <FieldSelectList
            fieldKey={field.key}
            value={editingValue}
            options={widgetField.options}
            onChange={handleArrayListChange(`${field.key}`)}
          />
        );
        break;
      case "arrayofstring":
        EditComponent = (
          <ArrayOfString
            selection={editingValue}
            options={widgetField.options}
            canEdit={widgetField.edit}
            title={field.key}
            onChange={handleArrayListChange(`${field.key}`)}
          />
        );
        break;
      default:
        EditComponent = (
          <Typography
            className={classes.error}
          >{`Missing Component Type ${field.type}`}</Typography>
        );
        break;
    }
    setEditComponent(EditComponent);
  }, [field.key, field.required, field.type, editingValue, classes.error]);

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        aria-label="Edit"
        color={isSet ? "primary" : "secondary"}
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Field</DialogTitle>
        <DialogContent>
          {EditComponent}
          <DialogContentText>{field.description}</DialogContentText>
          <Typography>path: {path}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave(editingValue)}
            color="primary"
            disabled={!isChanged}
          >
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FieldEditDialog;
