import { useReducer, createContext, useContext } from "react";

import setObject from "lodash-es/set";
import getObject from "lodash-es/get";
import omitObject from "lodash-es/omit";

import defaultConfig from "data/default-config.json";

const ConfiguratorStateContext = createContext();
const ConfiguratorDispatchContext = createContext();

const configuratorReducer = (state, action) => {
  const { config } = state;

  switch (action.type) {
    case "EDITING_PATH":
      return {
        ...state,
        editingPath: action.editingPath || null,
      };
    case "LOAD_CONFIG":
      return {
        ...state,
        config: action.config,
        editingPath: action.editingPath || null,
      };
    // {path, value}
    case "ADD_FIELD":
    case "ADD_FILE":
    case "ADD_FILE_COLLECTION":
    case "ADD_FOLDER_COLLECTION":
      const valueOfPath = getObject(config, action.path);
      valueOfPath.push(action.value);
      setObject(config, action.path, valueOfPath);
      return {
        ...state,
        config,
      };
    case "UPDATE_BACKEND":
    case "UPDATE_FIELD":
    case "UPDATE_FIELDS":
      setObject(config, action.path, action.value);
      return {
        ...state,
        config,
      };
    case "DELETE_COLLECTION":
    case "DELETE_FIELD_COLLECTION_ITEM":
      getObject(config, action.path).splice(action.index, 1);
      return {
        ...state,
        config,
      };
    case "DELETE_FILE":
    case "DELETE_FIELD":
      const newConfig =
        action.path === ""
          ? omitObject(config, action.index)
          : setObject(
              config,
              action.path,
              omitObject(getObject(config, action.path), action.index)
            );
      return {
        ...state,
        config: newConfig,
      };

    // case "CURRENTLY_UPDATING":
    //   return {
    //     ...state,
    //     currentlyUpdating: action.currentlyUpdating,
    //   };
    // case "LAST_UPDATED":
    //   return {
    //     ...state,
    //     lastUpdated: action.lastUpdated,
    //   };
    default:
      throw new Error(
        `Unhandled action type: ${action.type} in configuratorReducer`
      );
  }
};

function getInitialState(editingPath = "", config = { ...defaultConfig }) {
  return { editingPath, config };
}

export const useConfiguratorState = () => {
  const context = useContext(ConfiguratorStateContext);
  if (context === undefined) {
    throw new Error(
      `Context for useConfigurator is missing.\nUse withing <ConfiguratorProvider>`
    );
  }
  return context;
};

export const useConfiguratorDispatch = () => {
  const context = useContext(ConfiguratorDispatchContext);
  if (context === undefined) {
    throw new Error(
      `Context for useConfiguratorDispatch is missing.\nUse withing <ConfiguratorProvider>`
    );
  }
  return context;
};

export const ConfiguratorProvider = ({ editingPath, config, children }) => {
  const [state, updateContext] = useReducer(
    configuratorReducer,
    getInitialState(editingPath, config)
  );

  return (
    <ConfiguratorStateContext.Provider value={state}>
      <ConfiguratorDispatchContext.Provider value={updateContext}>
        {children}
      </ConfiguratorDispatchContext.Provider>
    </ConfiguratorStateContext.Provider>
  );
};
