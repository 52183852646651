import { Route, Switch } from "wouter";
import About from "scene/About";
import ConfiguratorPage from "scene/ConfiguratorPage";
import NotFound from "scene/404";

function App() {
  return (
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/" component={ConfiguratorPage} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
