import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListIcon from "@material-ui/icons/List";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import FileCollection from "components/configurator/FileCollection";
import FolderCollection from "components/configurator/FolderCollection";
import FolderCollectionAdd from "components/configurator/EditDialog/FolderCollection";
import FileCollectionAdd from "components/configurator/EditDialog/FileCollection";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const Collections = ({ path, collections }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { editingPath } = useConfiguratorState();
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === "backend");
  }, [editingPath]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddOpen = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleAddClose = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleAddFolder = (value) => {
    dispatch({
      type: "ADD_FOLDER_COLLECTION",
      path,
      value,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleAddFile = (value) => {
    dispatch({
      type: "ADD_FILE_COLLECTION",
      path,
      value,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`Collections${isEditing ? " - editing" : ""}`}
        />
        <ListItemSecondaryAction>
          {open && (
            <FolderCollectionAdd
              path={path}
              description="Add a folder collection by entering the required field values."
              onOpen={handleAddOpen}
              onClose={handleAddClose}
              onSave={handleAddFolder}
            />
          )}
          {open && (
            <FileCollectionAdd
              path={path}
              description="Add a file collection by entering the required field values. A default file will be created with default values that must be edited."
              onOpen={handleAddOpen}
              onClose={handleAddClose}
              onSave={handleAddFile}
            />
          )}
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding dense>
          {!isEditing &&
            collections.map((element, index) => {
              const Collection = element.folder
                ? FolderCollection
                : FileCollection;
              return (
                <Collection
                  key={`[${index}]`}
                  config={element}
                  index={index}
                  path={`${path}[${index}]`}
                  parentPath={`${path}`}
                  filesPath={`${path}[${index}].files`}
                />
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

export default Collections;
