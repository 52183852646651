import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ConfiguratorEditor from "components/configurator/editor";
import { ConfiguratorProvider } from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  titleRoot: {
    textAlign: "left",
  },
  outlined: {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Configurator = () => {
  const classes = useStyles();
  return (
    <ConfiguratorProvider>
      <Grid container spacing={0} className={classes.root}>
        <Grid className={classes.outlined} item xs={12} sm={12} md={12} lg={12}>
          <ConfiguratorEditor />
        </Grid>
      </Grid>
      <span className={classes.titleRoot}>This is the Configurator</span>
    </ConfiguratorProvider>
  );
};

export default Configurator;
