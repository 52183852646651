import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListIcon from "@material-ui/icons/List";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import DisplayField from "components/configurator/Field/display";
import { getViewFieldsByName } from "components/configurator/Backend/view-fields";
import BackendEditDialog from "components/configurator/EditDialog/Backend";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const Backend = ({ path = "backend", backend = {} }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { editingPath } = useConfiguratorState();
  const [editedBackend, setEditedBackend] = useState(backend);
  const [isEditing, setIsEditing] = useState(editingPath === path);
  const [viewFields, setViewFields] = useState(
    getViewFieldsByName(editedBackend.name || "github")
  );

  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === "backend");
  }, [editingPath]);

  useEffect(() => {
    setEditedBackend(backend);
  }, [backend]);

  useEffect(() => {
    const name = editedBackend.name || "github";
    setViewFields(getViewFieldsByName(name));
  }, [editedBackend.name]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDialogOpen = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleClose = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleEditBackend = (value) => {
    dispatch({
      type: "UPDATE_BACKEND",
      value,
      path,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    setEditedBackend(value);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`Backend - ${editedBackend.name}${
            isEditing ? " - editing" : ""
          }`}
        />
        <ListItemSecondaryAction>
          {open && (
            <BackendEditDialog
              backend={editedBackend}
              path={`${path}`}
              description="Edit the backend by entering the required values."
              onOpen={handleDialogOpen}
              onCancel={handleClose}
              onSave={handleEditBackend}
            />
          )}
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List className={classes.nested} disablePadding>
          {!isEditing &&
            viewFields.map((field, index) => {
              return (
                <DisplayField
                  key={`[${index}]`}
                  field={field}
                  value={editedBackend[field.key]}
                  defaultValue={editedBackend[field.default] || field.default}
                  path={`${path}.${field.key}`}
                  parentPath={`${path}`}
                />
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

export default Backend;
