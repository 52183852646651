import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import PageIcon from "@material-ui/icons/Description";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import FieldDeleteDialog from "components/configurator/EditDialog/Field/delete";
import Fields from "components/configurator/Fields";
import DisplayField from "components/configurator/Field/display";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

import { fields as defaultCollectionFields } from "data/configurator/collection/index.json";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const File = ({ index, config, path, parentPath, onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { editingPath } = useConfiguratorState();
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    setIsEditing(editingPath === path);
  }, [editingPath, path]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDeleteClickOpen = () => {
    setOpenDelete(!openDelete);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleDeleteClose = () => {
    if (typeof onClose === "function") onClose();
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleDelete = (parentPath) => {
    dispatch({
      type: "DELETE_COLLECTION",
      index,
      path: parentPath,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <>
      <ListItem button className={classes.set} onClick={handleClick}>
        <ListItemIcon>
          <PageIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`${config.label || config.name} [${config.name}]${
            isEditing ? " - editing" : ""
          }`}
          secondary={`name:${config.name} - ${config.file}`}
        />
        <FieldDeleteDialog
          title="Remove File"
          field={{
            required: false,
            description: `A file collection item [${
              config.label || config.name
            }]`,
          }}
          path={path}
          parentPath={parentPath}
          isSet
          open={openDelete}
          onOpen={handleDeleteClickOpen}
          onCancel={handleDeleteClose}
          onDelete={handleDelete}
          description={`Are you sure you want to remove File ${
            config.label || config.name
          } from the configuration?`}
        />
        <ListItemSecondaryAction>
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding dense>
          {!isEditing &&
            defaultCollectionFields
              .filter((i) => i.key !== "fields")
              .map((field, index) => {
                return (
                  <DisplayField
                    key={`[${index}]`}
                    field={field}
                    value={config[field.key]}
                    defaultValue={config[field.default] || field.default}
                    path={`${path}.${field.key}`}
                    parentPath={`${path}`}
                  />
                );
              })}
          {!isEditing && (
            <Fields fields={config.fields} path={`${path}.fields`} />
          )}
        </List>
      </Collapse>
    </>
  );
};

export default File;
