import { makeStyles } from "@material-ui/core/styles";
import Page from "components/PageComponent";
import Configurator from "components/configurator";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
}));

const ConfiguratorPage = (props) => {
  const classes = useStyles();
  const data = {};
  return (
    <Page image={data.image} title={data.title} update={data.update}>
      <div className={classes.root}>
        <Configurator />
      </div>
    </Page>
  );
};

export default ConfiguratorPage;
