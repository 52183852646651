import { useEffect, useState } from "react";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

import ConfiguratorBackend from "components/configurator/Backend";
import ConfiguratorCollections from "components/configurator/Collections";
import DisplayField from "components/configurator/Field/display";
import ViewerDropDialog from "components/configurator/viewer/DropDialog";
import UploadDialog from "components/configurator/viewer/UploadDialog";
import { useConfiguratorState } from "components/configurator/provider";

import { fields as defaultViewFields } from "data/configurator";

const FieldList = ({ config, viewFields = [] }) => {
  return config
    ? viewFields.map((field, index) => {
        return (
          <DisplayField
            key={index}
            field={field}
            value={config[field.key]}
            path={field.key}
            parentPath=""
            defaultValue={config[field.default] || field.default}
          />
        );
      })
    : null;
};

const ConfiguratorEditor = () => {
  const { editingPath, config } = useConfiguratorState();
  const [viewFields] = useState(
    defaultViewFields.filter((i) => i.key !== "fields").map((field) => field)
  );
  const [isEditing, setIsEditing] = useState(false);
  const [backend, setBackend] = useState(
    config.backend ||
      defaultViewFields
        .filter((i) => i.key !== "fields")
        .map((field) => (field.required ? field : null))
  );
  const [collections, setCollections] = useState(config.collections || []);

  useEffect(() => {
    const isEditing = editingPath === ".";
    setIsEditing(isEditing);
  }, [editingPath]);

  useEffect(() => {
    setBackend(config.backend || {});
    setCollections(config.collections || []);
  }, [config]);

  return (
    <List
      dense
      component="nav"
      subheader={
        <ListSubheader color="primary" disableSticky>
          Configuration
          {!isEditing && <ViewerDropDialog isSet view="yaml" />}
          <UploadDialog />
        </ListSubheader>
      }
    >
      <Divider />
      {!isEditing && backend && (
        <ConfiguratorBackend backend={backend} path="backend" />
      )}
      <Divider />
      {!isEditing && collections && (
        <ConfiguratorCollections collections={collections} path="collections" />
      )}
      <Divider />
      {!isEditing && <FieldList config={config} viewFields={viewFields} />}
    </List>
  );
};

export default ConfiguratorEditor;
