import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import FolderIcon from "@material-ui/icons/Folder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import FieldDeleteDialog from "components/configurator/EditDialog/Field/delete";
import { fields as defaultFolderCollectionFields } from "data/configurator/collection/folder";
import Fields from "components/configurator/Fields";
import DisplayField from "components/configurator/Field/display";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const FolderCollection = ({ config, index, path, parentPath, onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { editingPath } = useConfiguratorState();
  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    const editing = editingPath === path;
    setIsEditing(editing);
  }, [editingPath, path]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOpen = () => {
    setOpen(true);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    if (typeof onClose === "function") onClose();
  };

  const handleDelete = (path) => {
    dispatch({
      type: "DELETE_COLLECTION",
      path,
      index,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  return (
    <>
      <Divider />
      <ListItem button className={classes.set} onClick={handleClick}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={`${config.label || config.name} [${config.name}]${
            isEditing ? " - editing" : ""
          }`}
          secondary={config.folder ? `${config.folder}` : null}
        />
        <ListItemSecondaryAction>
          <FieldDeleteDialog
            title="Remove Folder"
            field={{
              required: false,
              description: `A Folder collection item [${
                config.label || config.name
              }]`,
            }}
            path={path}
            parentPath={parentPath}
            isSet
            open={open}
            onOpen={handleClickOpen}
            onCancel={handleClose}
            onDelete={handleDelete}
            description={`Are you sure you want to delete Folder ${
              config.label || config.name
            } from the configuration?`}
          />
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding dense>
          {!isEditing &&
            defaultFolderCollectionFields
              .filter((i) => i.key !== "fields")
              .map((field, index) => {
                return (
                  <DisplayField
                    key={`[${index}]`}
                    field={field}
                    onClose={handleClick}
                    value={config[field.key]}
                    defaultValue={config[field.default] || field.default}
                    path={`${path}.${field.key}`}
                    parentPath={`${path}`}
                  />
                );
              })}
          {!isEditing && (
            <Fields fields={config.fields} path={`${path}.fields`} />
          )}
        </List>
      </Collapse>
    </>
  );
};

export default FolderCollection;
