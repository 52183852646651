import { useState, forwardRef } from "react";
import { useLocation } from "wouter";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
/* icons */
import CloseIcon from "@material-ui/icons/Close";
import OpenIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  font: {
    fontSize: "calc(10px + 2vmin)",
  },
  flex: {
    flex: 1,
    fontSize: "calc(10px + 2vmin)",
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide ref={ref} direction="left" {...props} />;
});

const DialogMenu = (props) => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useLocation();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (to = "/") => {
    setLocation(to);
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleClickOpen} aria-label="Open">
        <OpenIcon />
      </IconButton>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => handleClose(location)}
              aria-label="close"
            >
              <CloseIcon className={classes.font} />
            </IconButton>
            <Typography color="inherit" className={classes.flex}>
              Menu
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem
            onClick={() => handleClose("/")}
            disabled={location === "/"}
            button
          >
            <ListItemText primary="Home" secondary="Home Page" />
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => handleClose("/about")}
            disabled={location === "/about"}
            button
          >
            <ListItemText primary="About" secondary="About the Configurator" />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
};

export default DialogMenu;
