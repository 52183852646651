import objectKeyBy from "lodash-es/keyBy";
import { defaults as defaultWidgets } from "data/configurator/widget";
import { types as defaultFieldTypes } from "data/configurator/field";

const defaultWidgetsObject = objectKeyBy(defaultWidgets, "key");
const defaultTypesObject = objectKeyBy(defaultFieldTypes, "key");

export const getWidgetViewFieldsByName = (widgetKey = "string") => {
  const viewFields = [];
  if (defaultWidgetsObject[widgetKey]) {
    defaultWidgetsObject[widgetKey].fields
      .filter((k) => k !== "widget")
      .forEach((fieldKey) => viewFields.push(defaultTypesObject[fieldKey]));
  }
  return viewFields;
};

export const getWidgetFieldByName = (fieldKey = "string") =>
  defaultTypesObject[fieldKey];
export const getWidgetObjectByName = (objectKey = "string") =>
  defaultWidgetsObject[objectKey];
