import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import { defaults as defaultBackends } from "data/configurator/backend";

const useStyles = makeStyles((theme) => ({
  text: {
    paddingLeft: 20,
  },
}));

const BackendSelect = ({ value: propValue, onChange, required = false }) => {
  const classes = useStyles();
  const [value, setValue] = useState(propValue || "");
  const [error, setError] = useState(false);
  //  state = {
  //     value: this.props.value,
  //     required: this.props.required,
  //   };

  const handleOnChange = (editValue) => {
    // // Must check for same value to not create a state value change loop
    if (editValue !== value) {
      setError(editValue === "");
      setValue(editValue);
      if (typeof onChange === "function") onChange(editValue);
      // this.setState({ value, error: editValue === "" });
      // if (typeof onChange === "function") onChange(editValue);
    }
  };

  const handleSelectChange = (event) => {
    handleOnChange(event.target.value);
  };

  const handleTextChange = (event) => {
    handleOnChange(event.target.value);
  };

  return (
    <>
      <InputLabel htmlFor="backend-select">Default Backends</InputLabel>
      <Select
        value={value}
        onChange={handleSelectChange}
        inputProps={{
          id: "backend-select",
        }}
      >
        {!required && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {defaultBackends.map((backend, index) => (
          <MenuItem key={`[${index}]`} value={backend.key}>
            {backend.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        className={classes.text}
        required={required}
        autoFocus
        error={error}
        margin="dense"
        id="name"
        label="name"
        value={value}
        onChange={handleTextChange}
        fullWidth
      />
    </>
  );
};

export default BackendSelect;
