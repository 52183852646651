import { fields as defaultCollectionFields } from 'data/configurator/collection/index.json'

const folderFields = [
  {
    key: 'folder',
    type: 'string',
    required: true,
    description: '(requires this or file): specifies the collection type and location; details in Collection Types'
  },
  {
    key: 'filter',
    type: 'string',
    description: ' optional filter for folder collections; details in Collection Types'
  },
  {
    key: 'create',
    type: 'boolean',
    default: false,
    description: 'for folder collections only; true allows users to create new items in the collection; defaults to false'
  },
  {
    key: 'slug',
    type: 'string',
    description: 'For folder collections where users can create new items, the slug option specifies a template for generating new filenames based on a file’s creation date and title field. (This means that all collections with create: true must have a title field.)'
  },
  {
    key: 'extension',
    type: 'string',
    description: 'determines the file extension searched for when finding existing entries in a folder collection and it determines the file extension used to save new collection items. It accepts the following values: yml, yaml, toml, json, md, markdown, html'
  }
]
defaultCollectionFields.map(element => folderFields.push(element))

export { folderFields as fields }
