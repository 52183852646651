import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import FileIcon from "@material-ui/icons/Note";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import FieldDeleteDialog from "components/configurator/EditDialog/Field/delete";
import File from "components/configurator/File";
import FileAdd from "components/configurator/EditDialog/File";
import {
  useConfiguratorDispatch,
  useConfiguratorState,
} from "components/configurator/provider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  set: {
    borderLeft: `${theme.configurator.layout.borderWidth} solid ${theme.palette.grey[600]}`,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const FileCollection = ({
  config,
  path,
  index,
  parentPath,
  filesPath,
  onDeleteClose,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { editingPath } = useConfiguratorState();

  const dispatch = useConfiguratorDispatch();

  useEffect(() => {
    const editing = editingPath === path;
    setIsEditing(editing);
  }, [editingPath, path]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddOpen = () => {
    setOpen(true);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleAddClose = () => {
    setOpen(false);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleAddFile = (value) => {
    dispatch({
      type: "ADD_FILE",
      value,
      path: filesPath,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const handleDeleteClickOpen = () => {
    setOpenDelete(!openDelete);
    dispatch({
      type: "EDITING_PATH",
      editingPath: path,
    });
  };

  const handleDeleteClose = () => {
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
    if (typeof onDeleteClose === "function") onDeleteClose();
  };

  const handleDelete = (path) => {
    dispatch({
      type: "DELETE_COLLECTION",
      path,
      index,
    });
    dispatch({
      type: "EDITING_PATH",
      editingPath: "",
    });
  };

  const primaryTitle = `${config.label || config.name} [${config.name}]${
    isEditing ? " - editing" : ""
  }`;
  return (
    <>
      <Divider />
      <ListItem button className={classes.set} onClick={handleClick}>
        <ListItemIcon>
          <FileIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={primaryTitle}
          secondary={config.file ? `${config.file}` : null}
        />
        <ListItemSecondaryAction>
          {open && (
            <FileAdd
              parentPath={`${path}`}
              path={`${filesPath}`}
              description="Add a file by entering the required field values."
              onOpen={handleAddOpen}
              onClose={handleAddClose}
              onSave={handleAddFile}
            />
          )}
          <FieldDeleteDialog
            title="Remove File Collection"
            field={{
              required: false,
              description: `A File collection [${config.label || config.name}]`,
            }}
            path={path}
            parentPath={parentPath}
            isSet
            open={openDelete}
            onOpen={handleDeleteClickOpen}
            onCancel={handleDeleteClose}
            onDelete={handleDelete}
            description={`Are you sure you want to delete File Collection ${
              config.label || config.name
            } from the configuration?`}
          />
          <IconButton color="primary" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested} disablePadding dense>
          {!isEditing &&
            config.files &&
            config.files.map((file, index) => (
              <File
                config={file}
                key={`[${index}]`}
                path={`${filesPath}[${index}]`}
                parentPath={`${filesPath}`}
                index={index}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

// const mapStateToProps = (state) => {
//   const {
//     currentlyUpdating,
//     lastUpdated,
//     config,
//   } = state.configuration.editing;

//   return {
//     fullConfig: config,
//     editingPath: currentlyUpdating,
//     updatedPath: lastUpdated.path,
//     lastUpdateValue: lastUpdated.value,
//   };
// };

export default FileCollection;
