import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    fontSize: "calc(10px + 2vmin)",
  },
}));

const AppHeader = (props) => {
  const classes = useStyles();
  return <header className={classes.root}>{props.children}</header>;
};

export default AppHeader;
